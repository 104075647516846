import React, { useState, useEffect } from 'react';
import { Container } from '@mui/material';
import { experimentalStyled as styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Chip from '@mui/material/Chip';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { formatDistanceToNow } from 'date-fns';



function Overview() {
  const [bots, setBots] = useState([]); // bots array
  const [sortOption, setSortOption] = useState('profit'); // sorting default
  const [value, setValue] = React.useState('1'); // tabs

  useEffect(() => {
    const ws = new WebSocket('wss://red.johnk.at/ws/data');

    // Function to handle opening the WebSocket connection
    const handleOpen = () => {
      console.log('Connected to WebSocket');
      ws.send(JSON.stringify({ status: 'connected' }));
    };

    // Function to handle messages received from the WebSocket
    const handleMessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(data);

      if (Array.isArray(data)) {
        // Handle initial list of bots
        setBots(data);
        ws.send(JSON.stringify({ status: 'update' }));
      } else {
        // Handle updates to individual bots
        updateBot(data);
      }
    };

    // Function to handle WebSocket errors
    const handleError = (error) => {
      console.error('WebSocket error:', error);
    };

    // Attaching event listeners
    ws.onopen = handleOpen;
    ws.onmessage = handleMessage;
    ws.onerror = handleError;

    // Cleanup function to close the WebSocket connection when the component unmounts
    return () => {
      ws.close();
    };
  }, []);

  // Function to update a bot's data
  const updateBot = (updatedBot) => {
    setBots((currentBots) => {
      const index = currentBots.findIndex((bot) => bot.id === updatedBot.id);
      if (index === -1) {
        // If the bot doesn't exist, add it to the array
        return [...currentBots, updatedBot];
      } else {
        // Update the existing bot's data
        const updatedBots = [...currentBots];
        updatedBots[index] = updatedBot;
        return updatedBots;
      }
    });
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  }));

  const toggleDetails = (accountId) => {
    setBots(currentBots =>
      currentBots.map(bot =>
        bot.account_id === accountId ? { ...bot, showDetails: !bot.showDetails } : bot
      )
    );
  };
  

  const calculatePnLPerSymbol = (closedTrades) => {
    const symbolPnL = closedTrades.reduce((acc, trade) => {
      if (!acc[trade.symbol]) {
        acc[trade.symbol] = 0;
      }
      acc[trade.symbol] += trade.realised_pnl;
      return acc;
    }, {});

    // Convert the object to an array of [symbol, pnl] and sort it
    const sortedSymbolPnL = Object.entries(symbolPnL).sort((a, b) => b[1] - a[1]);

    return sortedSymbolPnL;
  };

  const StatisticsTab = ({ closedTrades }) => {
    const sortedSymbolPnL = calculatePnLPerSymbol(closedTrades);

    return (
      <div className="active_trades statistics">
        <table>
            <tbody>
            {sortedSymbolPnL.map(([symbol, pnl]) => (
              <tr key={symbol}>
                <td>{symbol}</td>
                <td>{pnl.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };


  const getSortedBots = () => {
    const sortedBots = [...bots]; // Create a copy to avoid directly mutating the state
    switch (sortOption) {
      case 'a-z':
        return sortedBots.sort((a, b) => a.botname.localeCompare(b.botname));
      case 'profit':
        return sortedBots.sort((a, b) => {
          return parseFloat(b.closed_trades.reduce((acc, trade) => acc + trade.realised_pnl, 0)) -
                 parseFloat(a.closed_trades.reduce((acc, trade) => acc + trade.realised_pnl, 0));
        });
      case 'roi':
        return sortedBots.sort((a, b) => {
          const totalRoiA = a.closed_trades.reduce((acc, trade) => acc + trade.roi, 0) / a.closed_trades.length;
          const totalRoiB = b.closed_trades.reduce((acc, trade) => acc + trade.roi, 0) / b.closed_trades.length;
          return totalRoiB - totalRoiA; // For descending order
        });
      default:
        return sortedBots; // No sorting applied
    }
  };
  
  const calculateTotalClosedPnL = (closedTrades) => {
    return closedTrades.reduce((total, trade) => total + trade.realised_pnl, 0);
  };

  const calculateTotalOpenPnL = (openTrades) => {
    return openTrades.reduce((total, trade) => total + parseFloat(trade.unrealised_pnl), 0);
  };
  
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const displayTimeAgo = (timestamp) => {
    try {
      // Parsing the ISO timestamp string to a Date object
      const date = new Date(timestamp);
      // Check if the date is valid
      if (isNaN(date)) {
        throw new Error('Invalid Date');
      }
      // Using formatDistanceToNow to get the human-readable distance to now
      return formatDistanceToNow(date, { addSuffix: true });
    } catch (error) {
      console.error('Error parsing date:', timestamp);
      return 'Invalid date';
    }
  };
  

  const calculateTotalBalance = () => {
    return bots.reduce((total, bot) => total + parseFloat(bot.metrics.balance), 0).toFixed(2);
  };

  const calculateInitialBalance = () => {
    return bots.reduce((total, bot) => total + parseFloat(bot.metrics.initial_balance), 0).toFixed(2);
  }
  
  const calculateTotalEquity = () => {
    return bots.reduce((total, bot) => total + parseFloat(bot.metrics.equity), 0).toFixed(2);
  };
  
  const calculateTotalPnL = () => {
    return bots.reduce((total, bot) => total + parseFloat(bot.metrics.realisedPnl), 0).toFixed(2);
  };

  const calculateTotalOpenPnLAllBots = () => {
    return bots.reduce((total, bot) => total + bot.active_trades.reduce((acc, trade) => acc + parseFloat(trade.unrealised_pnl), 0), 0).toFixed(2);
  };

  const calculateTotalClosedTrades = () => {
    return bots.reduce((acc, bot) => acc + bot.closed_trades.length, 0);
};  

  
  const TotalEquity = calculateTotalEquity();
  const TotalPnl = calculateTotalPnL();
  const TotalBalance = calculateTotalBalance();
  const InitialBalance = calculateInitialBalance();
  const TotalPnlAllBots = calculateTotalOpenPnLAllBots();
  const totalClosedTrades = calculateTotalClosedTrades();  // Call the function to get the total closed trades

  const totalRoi = (TotalPnl / InitialBalance) * 100;
  const totalRoiColor = parseFloat(totalRoi) >= 0 ? '#86a55b' : '#ba7070';

  
  return (
    <Container maxWidth="xl">
      <Box sx={{ width: '100%' }}>
        <div className="ov-head ov-overview">
          <h2><span style={{color: totalRoiColor}}>{totalRoi.toFixed(2)} %</span> <span className="roiNew">{TotalPnl} $</span></h2>
          
          <div className="info">
            <div className="info-item">
              {parseFloat(TotalEquity).toLocaleString()} $

              <span>equity</span>
            </div>
            <hr></hr>
            <div className="info-item">
              {parseFloat(TotalBalance).toLocaleString()} $
              <span>balance</span>
            </div>
            <hr></hr>
            <div className="info-item">
              {TotalPnlAllBots}
              <span>open</span>
            </div>
            <hr></hr>
            <div className="info-item">
              {totalClosedTrades}
              <span>closed</span>
            </div>
          </div>
        </div>
      </Box>

      <h3>My Bots</h3>
      <Box sx={{ width: '100%' }}>
        <div className="ov-sorting">
          <Chip label="Sort by Profit"
                onClick={() => setSortOption('profit')}
                color={sortOption === 'profit' ? 'primary' : 'default'} />
          <Chip label="Sort A-Z"
                onClick={() => setSortOption('a-z')}
                color={sortOption === 'a-z' ? 'primary' : 'default'} />
        </div>

        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 8, md: 12 }}>
          {getSortedBots().map(bot => {
              // Calculate total closed PnL for the current bot
              const totalClosedPnL = calculateTotalClosedPnL(bot.closed_trades);
              const closedTradesCount = bot.closed_trades.length; // Get the count of closed trades

              const totalOpenPnL = calculateTotalOpenPnL(bot.active_trades);
              const totalOpenPnLDisplay = parseFloat(totalOpenPnL).toFixed(2); // Ensuring the open PnL is properly formatted

              const initialBalance = parseFloat(bot.metrics.initial_balance); // Ensuring the balance is in float format
              const totalProfit = parseFloat(totalClosedPnL.toFixed(2)); // Ensuring the profit is in float format

              const roi = (totalProfit / initialBalance) * 100; // Calculate ROI
              const roiColor = parseFloat(roi) >= 0 ? '#86a55b' : '#ba7070';

              return (
                <Grid item xs={2} sm={4} md={4} key={bot.account_id}>
                  <Item 
                    onClick={() => toggleDetails(bot.account_id)} 
                    className={`ov-item ${bot.showDetails ? 'active' : ''}`} 
                    style={{ cursor: 'pointer' }}>
                    <div className="ov-head">
                      <h3>{bot.botname}</h3>
                      <h2><span style={{color: roiColor}}>{roi.toFixed(2)} %</span> <span className="roiNew">{totalClosedPnL.toFixed(2)} $</span></h2>
                      
                      <div className="info">
                        <div className="info-item">
                          {parseFloat(bot.metrics.equity).toLocaleString()} $
                          <span>equity</span>
                        </div>
                        <hr></hr>
                        <div className="info-item">
                          {parseFloat(bot.metrics.balance).toLocaleString('en-US')} $
                          <span>balance</span>
                        </div>
                        <hr></hr>
                        <div className="info-item">
                          {totalOpenPnLDisplay} $
                          <span>open</span>
                        </div>
                        <hr></hr>
                        <div className="info-item">
                          {closedTradesCount}
                          <span>closed</span>
                        </div>
                      </div>
                      <ChevronRightIcon />
                    </div>

                    {bot.showDetails && (
                      <div className="additional-info TabPanel" onClick={e => e.stopPropagation()}>
                        <TabContext value={value}>
                          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth">
                              <Tab label="Open" value="1" sx={{ padding: '10px', fontSize: '14px' }} />
                              <Tab label="Closed" value="2" sx={{ padding: '10px', fontSize: '14px' }} />
                              <Tab label="Statistics" value="3" sx={{ padding: '10px', fontSize: '14px' }} />
                            </TabList>
                          </Box>
                          <TabPanel value="1">
                            {bot.active_trades.map((active_trade) => (
                              <div className={`active_trades ${active_trade.roi > 0 ? 'positive-roi' : ''}`} key={active_trade.id}>
                                <table>
                                  <tr>
                                    <td><b>{active_trade.symbol}</b></td>
                                    <td className="ac-time">{displayTimeAgo(active_trade.timestamp)}</td>
                                  </tr>
                                  <tr>
                                    <td>{active_trade.roi} % ({active_trade.unrealised_pnl} $)</td>
                                    <td className="ac-value">{active_trade.symbol_posvalue} $</td>
                                  </tr>
                                </table>
                              </div>
                            ))}
                          </TabPanel>
                          <TabPanel value="2">
                            {[...bot.closed_trades].reverse().map((closed_trade) => (
                              <div className={`active_trades ${closed_trade.roi > 0 ? 'positive-roi' : ''}`} key={closed_trade.id}>
                                <table>
                                  <tr>
                                    <td><b>{closed_trade.symbol}</b></td>
                                    <td className="ac-time">{displayTimeAgo(closed_trade.timestamp)}</td>
                                  </tr>
                                  <tr>
                                    <td>{closed_trade.roi} % ({closed_trade.realised_pnl} $)</td>
                                    <td className="ac-value">{closed_trade.symbol_qty} pcs</td>
                                  </tr>
                                </table>
                              </div>
                            ))}
                          </TabPanel>
                          <TabPanel value="3">
                            <StatisticsTab closedTrades={bot.closed_trades} />
                          </TabPanel>
                        </TabContext>                  
                      </div>
                    )}
                  </Item>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Container>
  );
}

export default Overview;
