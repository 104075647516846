import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, TextField, Container, Box, Snackbar, Alert, Slider, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

function Settings() {
  const [telegramChatId, setTelegramChatId] = useState('');
  const [timeout, setTimeout] = useState(1);
  const [symbols, setSymbols] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const token = localStorage.getItem('jwtToken');

  useEffect(() => {
    // Load settings
    fetch('https://red.johnk.at/api/get-settings', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => response.json()) // This line was corrected to ensure JSON parsing
    .then(data => {
      console.log(data);
      if (data.telegram_chat_id && data.timeout) {
        setTelegramChatId(data.telegram_chat_id);
        setTimeout(data.timeout);
      } else {
        console.error('Unexpected settings data:', data);
      }
    })
    .catch(error => console.error('Error loading settings:', error));
        
    // Load symbols list
    fetch('https://red.johnk.at/api/priority', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => setSymbols(data))
      .catch(error => console.error('Error loading symbols:', error));
  }, [token]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
  
    const items = Array.from(symbols);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    setSymbols(items);
  };

  const addSymbol = () => {
    let symbol = prompt('Enter new symbol:');
    if (!symbol) return; // Exit if no symbol was entered
  
    fetch(`https://red.johnk.at/api/symbolchecker?symbol=${symbol}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      if (response.status === 200) {
        setSymbols([...symbols, { symbol, blocked: "false" }]);
      } else if (response.status === 205) {
        alert('Symbol is incorrect');
      }
    })
    .catch(error => console.error('Error checking symbol:', error));
  };

  const saveSymbols = () => {
    const symbolsWithPriority = symbols.map((symbol, index) => ({
      ...symbol,
      priority: index
    }));
  
    fetch('https://red.johnk.at/api/priority', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(symbolsWithPriority),
    })
    .then(response => {
      if (!response.ok) throw new Error('Failed to save symbols');
      return response.json();
    })
    .then(() => {
      return fetch('https://red.johnk.at/api/update-priority', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    })
    .then(response => {
      if (!response.ok) throw new Error('Failed to update settings');
      return response.json();
    })
    .then(() => {
      // Show success message for syncing settings
      setSnackbarMessage('Prio list synced successfully');
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
    })
    .catch(error => {
      console.error('Error:', error);
      setSnackbarMessage(error.message || 'Error syncing settings');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    });
  };

  
  // Handle settings form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    const settings = {
      telegram_chat_id: telegramChatId,
      timeout
    };

    fetch('https://red.johnk.at/api/settings', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(settings),
    })
    .then(response => {
      if (!response.ok) throw new Error('Failed to save settings');
      return response.json();
    })
    .then(() => {
      return fetch('https://red.johnk.at/api/update-settings', {
        method: 'GET', 
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
    })
    .then(response => {
      if (!response.ok) throw new Error('Failed to update settings');
      return response.json();
    })
    .then(() => {
      // Show success message
      setSnackbarMessage('Settings synced successfully');
      setSnackbarSeverity('info');
      setSnackbarOpen(true);
    })
    .catch(error => {
      console.error('Error:', error);
      setSnackbarMessage(error.message || 'Error syncing settings');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    });
  };
  
  const handleTimeoutChange = (event, newValue) => {
    setTimeout(newValue);
  };

  const deleteSymbol = (index) => {
    // Create a new array excluding the item at the specified index
    const newSymbols = symbols.filter((_, symbolIndex) => symbolIndex !== index);
    setSymbols(newSymbols);
  };
  
  return (
    <div style={{ maxWidth: '80%', margin: 'auto' }}>
      <h1>Settings</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div className="singleBox settings">
        <h2>Bot settings</h2>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Telegram Chat ID"
            variant="outlined"
            value={telegramChatId}
            onChange={e => setTelegramChatId(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Typography id="timeout-slider" gutterBottom>
            Timeout (1-20 minutes)
          </Typography>
          <Slider
            value={timeout}
            onChange={handleTimeoutChange}
            aria-labelledby="timeout-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={20}
          />
          <Button variant="contained" type="submit" sx={{ mt: 2 }}>Save Settings</Button>
        </form>
      </div>
      <div className="singleBox settings">
        <h2>Symbol priority list</h2>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="symbols">
            {(provided) => (
                <div
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {symbols.map(({ symbol }, index) => (
                  <Draggable key={symbol} draggableId={symbol} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          display: 'flex', // Ensure items are aligned in a row
                          alignItems: 'center', // Center items vertically
                          marginBottom: "8px",
                          padding: "8px 25px",
                          backgroundColor: snapshot.isDragging ? "#635dff" : "#212546",
                          color: "#FFFFFF",
                          borderRadius: "8px",
                          boxShadow: snapshot.isDragging ? "0px 0px 10px #00000010" : "none",
                          border: "1px solid #3E4574",
                        }}
                      >
                        {index}: {symbol}
                        <IconButton
                          onClick={() => deleteSymbol(index)}
                          style={{ marginLeft: 'auto', color: 'white' }}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button variant="contained" onClick={addSymbol} style={{ marginTop: "20px" }}>Add Symbol</Button>
        <Button variant="contained" onClick={saveSymbols} style={{ marginTop: "20px", marginLeft: "10px" }}>Save List</Button>
      </div>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </div>
    </div>
  );
}

export default Settings;
