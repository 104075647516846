import React, { useState, useEffect } from 'react';
import { Container, Box, Button, Slider, TextField, Typography, Grid, Snackbar, Alert, Modal, Paper, InputAdornment } from '@mui/material';

function AccountManagement() {
  const token = localStorage.getItem('jwtToken');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ botname: '', api_key: '', api_secret: '', account_id: '' });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteConfirmName, setDeleteConfirmName] = useState('');
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    fetch('https://red.johnk.at/api/users', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => response.json())
    .then(data => {
      const initializedUsers = data.map(user => ({
        ...user,
        initial_balance: user.initial_balance || 0,
        amount_bear: user.amount_bear || 0,
        amount_bull: user.amount_bull || 0,
        account_leverage: user.account_leverage || 0,
      }));
      setUsers(initializedUsers);
    })
    .catch(console.error);
  }, []);

  const handleAddUser = (e) => {
    e.preventDefault();
    fetch('https://red.johnk.at/api/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(newUser),
    })
    .then(response => response.json())
    .then(data => {
      setUsers(prevUsers => [...prevUsers, data]);
      setNewUser({ botname: '', api_key: '', api_secret: '', account_id: '' }); // Reset form
      handleOpenSnackbar('User added successfully', 'success');
    })
    .catch(error => {
      console.error('Failed to add user:', error);
      handleOpenSnackbar(`Failed to add user: ${error.message}`, 'error');
    });
  };

  const handleOpenDeleteModal = (user) => {
    setUserToDelete(user);
    setDeleteModalOpen(true);
  };

  const handleDeleteUser = () => {
    if (userToDelete && deleteConfirmName === userToDelete.botname) {
      fetch(`https://red.johnk.at/api/users/${userToDelete.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
      .then(() => {
        setUsers(users.filter(user => user.id !== userToDelete.id));
        handleOpenSnackbar('User deleted successfully', 'success');
        setDeleteModalOpen(false);
        setDeleteConfirmName('');
        setUserToDelete(null);
      })
      .catch(console.error);
    } else {
      handleOpenSnackbar('Account name does not match. Please try again.', 'error');
    }
  };

  const handleChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleSaveChanges = (user) => {
    updateUserData(user.id, user);
  };

  const handleSliderChange = (userId, settingName, newValue) => {
    setUsers(users.map(user => 
      user.id === userId ? { ...user, [settingName]: newValue } : user
    ));
  };

  const handleUserDetailChange = (userId, detailName, newValue) => {
    const numericValue = newValue.replace(/,/g, '');
    setUsers(users.map(user =>
      user.id === userId ? { ...user, [detailName]: numericValue } : user
    ));
  };
  const handleTextFieldChange = (e, userId) => {
    const { name, value } = e.target;
    handleUserDetailChange(userId, name, value);
  };

  const formatNumber = (number) => {
    // Ensure conversion to number, then back to localized string
    const numeric = Number(number);
    if (!isNaN(numeric)) {
      return numeric.toLocaleString();
    }
    return '';
  };

  const updateUserData = (userId, updatedData) => {
    fetch(`https://red.johnk.at/api/users/${userId}`, {
      method: 'PUT', // Using PUT for full resource updates
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(updatedData),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Failed to update user data');
      }
      return response.json();
    })
    .then(data => {
      setUsers(users.map(user => user.id === userId ? { ...user, ...data } : user));
      handleOpenSnackbar('User settings updated successfully', 'success');
    })
    .catch(error => {
      console.error('Failed to update user:', error);
      handleOpenSnackbar(`Failed to update user: ${error.message}`, 'error');
    });
  };

  const handleOpenSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" sx={{ mb: 4 }}>Account Management</Typography>
      <Box component="form" onSubmit={handleAddUser} noValidate autoComplete="off" sx={{ mb: 4 }}>
        <TextField label="Account Name" variant="outlined" name="botname" value={newUser.botname} onChange={handleChange} required sx={{ mr: 2 }} />
        <TextField label="API Key" variant="outlined" name="api_key" value={newUser.api_key} onChange={handleChange} required sx={{ mr: 2 }} />
        <TextField label="API Secret" variant="outlined" name="api_secret" value={newUser.api_secret} onChange={handleChange} required sx={{ mr: 2 }} />
        <TextField label="Account ID" variant="outlined" name="account_id" value={newUser.account_id} onChange={handleChange} required sx={{ mr: 2 }} />
        <Button type="submit" variant="contained" color="primary">Add User</Button>
      </Box>


      <Grid container spacing={3}>
        {users.map((user) => (
          <Grid item xs={12} sm={6} md={4} key={user.id}>
            <Box mb={1} className="acc-box" sx={{ border: '1px solid grey', borderRadius: '5px', padding: 2, height: '100%' }}>
              <Typography variant="h5" gutterBottom><b>{user.botname}</b></Typography>
              <Typography variant="body1">Account ID: {user.account_id}</Typography>
              {/* TextFields for amount_bear, amount_bull, and account_leverage */}
              {['amount_bear', 'amount_bull', 'account_leverage'].map(setting => (
                <Box key={setting} sx={{ mt: 2 }}>
                  <Typography gutterBottom>{setting.replace('_', ' ')}: <b>{user[setting]}</b></Typography>
                  <Slider
                    value={user[setting]}
                    onChange={(e, newValue) => handleSliderChange(user.id, setting, newValue)}
                    aria-labelledby="input-slider"
                    min={0}
                    max={21}
                    step={.1}
                    valueLabelDisplay="auto"
                  />
                </Box>
              ))}
              <TextField
                label="Initial Balance"
                variant="outlined"
                name="initial_balance"
                value={formatNumber(user.initial_balance)}
                onChange={(e) => handleTextFieldChange(e, user.id)}
                required
                sx={{ mt: 3 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="h6">$</Typography>
                    </InputAdornment>
                  ),
                }}
              />
              <br /><br />
              <Button variant="contained" color="primary" sx={{ mt: 2, mr: 1 }} onClick={() => handleSaveChanges(user)}>Save</Button>
              <Button variant="contained" color="secondary" onClick={() => handleOpenDeleteModal(user)}>Delete</Button>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Typography id="delete-modal-title" variant="h6" component="h2">
            Confirm Delete
          </Typography>
          <Typography id="delete-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete account {userToDelete ? userToDelete.botname : ''}? Type the account name to confirm.
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            name="deleteConfirmName"
            label="Account Name"
            type="text"
            id="deleteConfirmName"
            autoComplete="delete-confirm-name"
            value={deleteConfirmName}
            onChange={(e) => setDeleteConfirmName(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button onClick={handleDeleteUser} variant="contained" color="secondary" sx={{ mt: 3, mb: 2 }}>
            Confirm Delete
          </Button>
        </Box>
      </Modal>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AccountManagement;
