import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Tooltip } from '@mui/material';
import { ChevronLeft, ChevronRight, AccessTime } from '@mui/icons-material';
import dayjs from 'dayjs';

function Logs() {
  const [data, setData] = useState([]);
  const [currentDate, setCurrentDate] = useState(dayjs());

  useEffect(() => {
    dayjs.locale('de'); // Set the locale to German
    const fetchData = async () => {
      const token = localStorage.getItem('jwtToken');
      try {
        const response = await fetch('https://red.johnk.at/api/logs', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
  }, []);

  const changeDate = (days) => {
    setCurrentDate(prevDate => prevDate.add(days, 'day'));
  };

  const formatDate = (date) => dayjs(date).format('DD. MMMM YYYY HH:mm:ss');
  const calculateDuration = (start, finish) => {
    if (start === "unfinished" || finish === "unfinished") {
      return "timestamp missing";
    }
    const startDate = dayjs(start);
    const finishDate = dayjs(finish);
    const duration = finishDate.diff(startDate, 'millisecond');
    const minutes = Math.floor(duration / 60000);
    const milliseconds = duration % 60000;
    return `${minutes} minutes ${milliseconds} ms`;
  };

  const getCircleColor = (result) => {
    if (result === 'OK') {
      return '#050';
    } else if (result === 'current position is zero, cannot fix reduce-only order qty' || result === "ab not enough for new order") {
      return '#b57500';
    } else {
      return '#750000';
    }
  };

  const filteredData = data.filter(row => dayjs(row.timestamp_start).isSame(currentDate, 'day'));

  return (
    <div style={{ maxWidth: '80%', margin: 'auto' }}>
      <h1>Trade Logs</h1>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
        <IconButton onClick={() => changeDate(-1)}>
          <ChevronLeft />
        </IconButton>
        <h2>{currentDate.format('DD. MMMM YYYY')}</h2>
        <IconButton onClick={() => changeDate(1)}>
          <ChevronRight />
        </IconButton>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Script Name</TableCell>
              <TableCell align="left">Account Name</TableCell>
              <TableCell align="left">Symbol</TableCell>
              <TableCell align="left">Action</TableCell>
              <TableCell align="left">Duration</TableCell>
              <TableCell align="left">Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{row.botname}</TableCell>
                <TableCell align="left">{row.accountname}</TableCell>
                <TableCell align="left">{row.symbol}</TableCell>
                <TableCell align="left">{row.action}</TableCell>
                <TableCell align="left">
                  <Tooltip title={row.timestamp_start === "unfinished" || row.timestamp_fin === "unfinished" ? "timestamp missing" : `Start: ${formatDate(row.timestamp_start)} | Finish: ${formatDate(row.timestamp_fin)}`}>
                    <span>
                      <AccessTime style={{ fontSize: '1.25em', verticalAlign: 'middle' }} /> {calculateDuration(row.timestamp_start, row.timestamp_fin)}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell align="left">
                  <div style={{ width: 20, height: 20, borderRadius: '50%', backgroundColor: getCircleColor(row.result), display: 'inline-block' }}></div><br></br>
                  {row.result}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Logs;
