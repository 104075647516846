import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Switch, FormControl, InputLabel, Select, MenuItem, FormGroup, FormControlLabel, IconButton } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import dayjs from 'dayjs';

function formatDate(timestamp) {
  const options = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  return new Intl.DateTimeFormat('de-DE', options).format(new Date(timestamp));
}

function Trades() {
  const [data, setData] = useState([]);
  const [actionFilter, setActionFilter] = useState('');
  const [symbolFilter, setSymbolFilter] = useState([]);
  const [botNameFilter, setBotNameFilter] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'descending' });
  const [currentDate, setCurrentDate] = useState(dayjs());

  useEffect(() => {
    dayjs.locale('de'); // Set the locale to German
    const fetchData = async () => {
      const token = localStorage.getItem('jwtToken');
      console.log(token);
      try {
        const response = await fetch('https://red.johnk.at/api/trades', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    fetchData();
  }, []);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const changeDate = (days) => {
    setCurrentDate(prevDate => prevDate.add(days, 'day'));
  };

  const sortedData = React.useMemo(() => {
    let sortableItems = [...data];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [data, sortConfig]);

  const filteredData = React.useMemo(() => {
    return sortedData.filter(row => dayjs(row.timestamp).isSame(currentDate, 'day'))
      .filter((row) => (
        (actionFilter ? row.action === actionFilter : true) &&
        (symbolFilter.length ? symbolFilter.includes(row.symbol) : true) &&
        (botNameFilter.length ? botNameFilter.includes(row.botName) : true)
      ));
  }, [sortedData, actionFilter, symbolFilter, botNameFilter, currentDate]);

  // Extract unique symbols and bot names for filter options
  const symbols = [...new Set(data.map(item => item.symbol))];
  const botNames = [...new Set(data.map(item => item.botName))];

  const getColorForROI = (roi) => roi < 0 ? 'red' : 'green';

  const handleActionChange = (event) => {
    setActionFilter(event.target.checked ? 'sell' : '');
  };

  const handleSymbolChange = (event) => {
    const { target: { value } } = event;
    setSymbolFilter(typeof value === 'string' ? value.split(',') : value);
  };

  const handleBotNameChange = (event) => {
    const { target: { value } } = event;
    setBotNameFilter(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <div style={{ maxWidth: '80%', margin: 'auto' }}>
      <h1>Trades</h1>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
        <IconButton onClick={() => changeDate(-1)}>
          <ChevronLeft />
        </IconButton>
        <h2>{currentDate.format('DD. MMMM YYYY')}</h2>
        <IconButton onClick={() => changeDate(1)}>
          <ChevronRight />
        </IconButton>
      </div>
      <FormGroup row className="trades-filter">
        <FormControlLabel
          control={<Switch checked={actionFilter === 'sell'} onChange={handleActionChange} />}
          label="Show Sell Trades Only"
        />
        <FormControl variant="outlined" style={{ minWidth: 120, marginRight: 10 }}>
          <InputLabel>Symbols</InputLabel>
          <Select
            multiple
            value={symbolFilter}
            onChange={handleSymbolChange}
            label="Symbols"
            renderValue={(selected) => selected.join(', ')}
          >
            {symbols.map((symbol) => (
              <MenuItem key={symbol} value={symbol}>
                {symbol}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" style={{ minWidth: 120 }}>
          <InputLabel>Bot Names</InputLabel>
          <Select
            multiple
            value={botNameFilter}
            onChange={handleBotNameChange}
            label="Bot Names"
            renderValue={(selected) => selected.join(', ')}
          >
            {botNames.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormGroup>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell onClick={() => requestSort('botName')}>Script Name</TableCell>
              <TableCell align="right">Account Name</TableCell>
              <TableCell align="right" onClick={() => requestSort('symbol')}>Symbol</TableCell>
              <TableCell align="right" onClick={() => requestSort('action')}>Action</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Number of Tokens</TableCell>
              <TableCell align="right">Leverage</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Coin Symbol</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">ROI</TableCell>
              <TableCell align="right">Closed Pnl</TableCell>
              <TableCell align="right">Profit</TableCell>
              <TableCell align="right">Entry Price</TableCell>
              <TableCell align="right">Exit Price</TableCell>
              <TableCell align="right">Timestamp</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{row.botName}</TableCell>
                <TableCell align="right">{row.accountName}</TableCell>
                <TableCell align="right">{row.symbol}</TableCell>
                <TableCell align="right">{row.action}</TableCell>
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell align="right">{row.numberOfTokens}</TableCell>
                <TableCell align="right">{row.leverage}</TableCell>
                <TableCell align="right">{row.status}</TableCell>
                <TableCell align="right">{row.coinSymbol}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
                <TableCell align="right" style={{ color: getColorForROI(row.roi) }}>{row.roi}</TableCell>
                <TableCell align="right">{row.closedPnl}</TableCell>
                <TableCell align="right">{row.profit}</TableCell>
                <TableCell align="right">{row.avgEntryPrice}</TableCell>
                <TableCell align="right">{row.avgExitPrice}</TableCell>
                <TableCell align="right">{formatDate(row.timestamp)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Trades;
